<template>
  <div class="home container" style="padding-top: 10px ;padding-bottom:100px;">
    <div class="modal-page" v-if="isGme || isSuyatsu || isLovecosme2">
      <div class="modal-box" v-if="isLovecosme2">
      <a href="https://service.seiheki-matching.jp/dmm_seiheki" target="_blank">
        <img src="@/assets/modal/dmm_modal.png" style="width:100%;">
      </a>
        <br>
        <br>
        <input type="checkbox" v-model="isViewLovecosme2"> 次回から表示しない
        <br>
        <br>
        <button class="btn-rank-request" style="width:60%;" @click="onModal(1)">
          閉じる
        </button>
      </div>
      <div class="modal-box" v-if="isGme">
      <a href="https://service.seiheki-matching.jp/gme_seiheki" target="_blank">
        <img src="@/assets/modal/gme_modal.png" style="width:100%;">
      </a>
        <br>
        <br>
        <input type="checkbox" v-model="isViewGme"> 次回から表示しない
        <br>
        <br>
        <button class="btn-rank-request" style="width:60%;" @click="onModal(2)">
          閉じる
        </button>
      </div>
      <div class="modal-box" v-if="isSuyatsu">
      <a href="https://service.seiheki-matching.jp/sakuranokoineko-2" target="_blank">
        <img src="@/assets/modal/pop.jpg" style="width:100%;">
      </a>
        <br>
        <br>
        <input type="checkbox" v-model="isViewSuyatsu"> 次回から表示しない
        <br>
        <br>
        <button class="btn-rank-request" style="width:60%;" @click="onModal(3)">
          閉じる
        </button>
      </div>
    </div>
    <div v-show="isLoading" style="padding-top:60px;">
      <Spinner></Spinner>
    </div>
    <div v-show="!isLoading && active_status!='inactive'">
     <transition name="bounce">
      <span>
        <div v-show="message_flag && age_conf != 2" class="flash"><p class="flash-text"><Icon name="info-circle" scale="1" style="color:#775DDF"/> {{ ageConfMessage }}</p></div>
        <div v-show="message_flag && form_post" class="flash"><p class="flash-text"><Icon name="info-circle" scale="1" style="color:#775DDF"/>{{ ageConfMessage }}</p></div>
        <div v-show="message_flag && ng_commit" class="flash"><p class="flash-text"><Icon name="info-circle" scale="1" style="color:#775DDF"/>ノーマッチンにしました。<br>次のレコメンドをお待ちください。</p></div>
        <div v-show="message_flag && message_text!=''" class="flash"><p class="flash-text"><Icon name="info-circle" scale="1" style="color:#775DDF"/>{{message_text}}</p></div>
      </span>
    </transition>
    <v-dialog
      v-model="loginBonusDialog"
      width="500"
    >
      <div style="background:white;color: #222222;padding:20px;">
      <transition name="expand">
          <Icon name="heart" scale="5" style="" class="heart-icon"/>
      </transition>
      <p class="matching-success">+{{login_bonus_amount}}いいね獲得</p>
      <p>キャンペーンログインボーナスを獲得しました！</p>
      </div>
    </v-dialog>
    <v-dialog
      v-model="AnouncementDialog"
      width="500"
      scrollable
      persistent
      
    >
    <v-card>
      <div style="background:white;color: #222222;padding:20px;">
      <h2 v-if="notification_reads && notification_reads.length > 0">{{ notification_reads[0].title }}</h2>

      <p  v-if="notification_reads && notification_reads.length > 0" style="text-align: left; white-space: pre-line;">{{ notification_reads[0].body }}</p>
      <v-divider></v-divider>
        <button class="btn-comp login-btn" style="margin-bottom:10px;" @click="postNotificationRead()">お知らせ確認</button>
      </div>
    </v-card>
    </v-dialog>
    <div v-if="sex==1 && payment_conf==1">
      <h2>プロフィール充実度</h2>
      <div>
        <Ranktile :rank="profile_complete_rate.point+'%'" :completedSteps="completedSteps"/>
      </div>
      <div style="margin-bottom:0 !important; display:flex; flex-direction:row;" class="white-card">
            <img :src="require('@/assets/seiheki-chan.jpg')" style="width:100px; height:100px; border-radius:50px; border:3px solid white;">
            <div style="height:100px; align-items: center; vertical-align: middle; display:flex;">
              <p style="padding-left:15px; font-weight: 900; text-align: left; margin-bottom:0;">プロフィール充実度があがるとレコメンドされやすくなります！</p>
            </div>
      </div>
      <div class="container white-card left">
        <router-link to="/form" class="small-link">
        <p style="margin:0"><Icon name="check-square" scale="1" style="color:#5600CE" v-if="this.profile_complete_rate.c1"/><Icon name="square" scale="1" style="color:gray" v-else/>プロフィール登録 +30%</p>
        </router-link>
        <router-link to="/form?only_profile_img=true" class="small-link">
        <p style="margin:0"><Icon name="check-square" scale="1" style="color:#5600CE" v-if="this.profile_complete_rate.c2"/><Icon name="square" scale="1" style="color:gray" v-else/>画像1枚登録 +5%</p>
        </router-link>
        <router-link to="/form?only_profile_img=true" class="small-link">
        <p style="margin:0"><Icon name="check-square" scale="1" style="color:#5600CE" v-if="this.profile_complete_rate.c3"/><Icon name="square" scale="1" style="color:gray" v-else/>画像2枚登録 +5%</p>
        </router-link>
        <router-link to="/form?only_profile_img=true" class="small-link">
        <p style="margin:0"><Icon name="check-square" scale="1" style="color:#5600CE" v-if="this.profile_complete_rate.c4"/><Icon name="square" scale="1" style="color:gray" v-else/>画像3枚登録 +10%</p>
        </router-link> 
        <router-link to="/form?only_appear_points=true" class="small-link">
        <p style="margin:0"><Icon name="check-square" scale="1" style="color:#5600CE" v-if="this.profile_complete_rate.c5"/><Icon name="square" scale="1" style="color:gray" v-else/>アピールポイント登録 +10%</p>
        </router-link>
        <router-link to="/form?only_propensity=true" class="small-link">
        <p style="margin:0"><Icon name="check-square" scale="1" style="color:#5600CE" v-if="this.profile_complete_rate.c6"/><Icon name="square" scale="1" style="color:gray" v-else/>性癖自由記述登録50字 +5%</p>
        </router-link>
        <router-link to="/form?only_propensity=true" class="small-link">
        <p style="margin:0"><Icon name="check-square" scale="1" style="color:#5600CE" v-if="this.profile_complete_rate.c7"/><Icon name="square" scale="1" style="color:gray" v-else/>性癖自由記述登録140字 +5%</p>
        </router-link>
        <router-link to="/form?only_propensity=true" class="small-link">
        <p style="margin:0"><Icon name="check-square" scale="1" style="color:#5600CE" v-if="this.profile_complete_rate.c8"/><Icon name="square" scale="1" style="color:gray" v-else/>性癖自由記述登録300字 +10%</p>
        </router-link>
        <router-link to="/form?only_sns=true" class="small-link">
        <p style="margin:0"><Icon name="check-square" scale="1" style="color:#5600CE" v-if="this.profile_complete_rate.c9"/><Icon name="square" scale="1" style="color:gray" v-else/>SNSアカウント登録 +20%</p>
        </router-link>
        
      </div>
    </div>
    <h2 v-if="sex==2 && age_conf==2">レコメンドをもらう</h2>
    <div class="container" v-if="sex==2 && [3,5].includes(status)">
      <div class="white-card">
        <div style="padding:25px">
          <p style="margin-bottom:0; font-weight:900;">レコメンドを受け取りたい場合は↓のボタンをタップしてください。</p>
          <button class="btn-comp login-btn" @click="submitStatusActivate()">レコメンドちょうだい！</button>
        </div> 
      </div>
    </div>
    <div class="container" v-if="sex==2 && recommend_id != null">
      <div class="white-card">
        <router-link :to="{ name: 'Profile', query: { id: recommend_id }}"><p style="margin-bottom:0;"><b><span style="color:#f85b8c">New!</span> 新着レコメンドがあります</b></p></router-link>
      </div>
    </div>
    <div class="container" v-if="sex==2 && matching_id != null && false">
      <div class="white-card">
        <router-link :to="{ name: 'Profile', query: { id: matching_id }}"><p style="margin-bottom:0;"><b><span style="color:#f85b8c"></span>男性から返信はありましたか？</b></p></router-link>
      </div>
    </div>
    <div class="container" v-if="sex==2 && suspend_id != null">
      <div class="white-card">
        <router-link :to="{ name: 'Profile', query: { id: suspend_id }}"><p style="margin-bottom:0;"><b><span style="color:#f85b8c"></span>保留中のレコメンドがあります</b></p></router-link>
      </div>
    </div>

    <div class="container" v-if="sex==2 && age_conf==2 && recommendable && !mannual_recommend && suspend_id == null && recommend_id == null && recommend_create_count<max_recommend_count && matching_id == null && [4].includes(status)">
    </div>
    <div class="container" v-if="sex==2 && age_conf==2  && recommend_create_count + manual_recommend_count < max_recommend_count ">
        <div class="white-card" style="margin-bottom: 30px;">
          <p class="limit-announce-text">本日残り <span style="font-size:3rem;font-weight:900;">{{( max_recommend_count - recommend_create_count - manual_recommend_count) > 0 ?  max_recommend_count - recommend_create_count - manual_recommend_count: 0}}</span> 件 3:00 にリセット</p>
        </div>
        <div class="row">
          <div class="container col-md-6">
            <button class="btn-recommend-request" @click="getNewRecommend()">
            AIレコメンド
            </button>
            <p></p>
        <router-link to="/conditionsform" class="small-link" v-show="false">
          <v-btn 
            small
            tile
            >
            <Icon name="cog" scale="1" style="color:gray;" />条件を設定する
          </v-btn>
        </router-link>
          </div>
          <div class="container col-md-6">
            <button class="btn-recommend-request-mannual" @click="submitMannualRecommend()">
              性癖ちゃんからレコメンド@{{manual_recommend_ticket}}回
            </button>
          </div>

        </div>
        <p></p>

    </div>
    <div class="container white-card" v-if="sex==2 && age_conf==2 && mannual_recommend">
      <div class="container">
        <div style="display:flex; flex-direction:row; ">
          <img :src="require('@/assets/seiheki-chan.jpg')" style="width:100px; height:100px; border-radius:50px; border:3px solid white;">
          <div style="height:100px; align-items: center; vertical-align: middle; display:flex;">
            <p style="padding-left:15px; font-weight: 900; text-align: left; margin-bottom:0;">性癖が合う男性を探しています！準備ができたらTopページにアラートが出ます！お待ちくださいね！</p>
          </div>
        </div>
      </div>

    </div>
    <div class="container white-card" v-if="sex==2 && age_conf==2 && recommend_create_count + manual_recommend_count>=max_recommend_count">
      <h3 style="color:#686181; font-weight:900; padding-bottom:0; margin-top:1rem; font-size:1.5rem;"><Icon name="exclamation-triangle" scale="1.4" style="color:#f85b8c;padding-bottom:3px;" /> 上限に達しました <Icon name="exclamation-triangle" scale="1.4" style="color:#f85b8c;padding-bottom:3px;" /></h3>
      <hr style="margin-top:10px;margin-bottom:10px;">
      <p style="text-align: left; font-weight:900; margin-bottom:0;">
      本日のレコメンド上限に達しました。<br>毎日午前3時にカウントリセットされます。</p>
      <hr style="margin-top:10px;margin-bottom:10px;">
      <p style="font-weight:900; color:#f85b8c;">※1日<span>{{ max_recommend_count }}</span>件まで</p>

    </div>
    <h2>最新情報</h2>
    <div class="container" v-if="sex==2 && status==5">
      <div class="white-card">
        <p style="margin: auto; color:#f85b8c;">レコメンド一時停止中です。<br>レコメンド再開はレコメンドされている<br>運営twitterアカウントへご連絡ください。</p>
      </div>
    </div>
    <div class="container" v-if="sex==2 && status==6">
      <div class="white-card">
        <p style="margin: auto;color:#f85b8c;">twitterアカウントが検出できません。ご登録のtwitter IDをご確認ください。</p>
      </div>
    </div>
    <div class="container" v-if="sex==2 && status==2">
      <div class="white-card">
        <p style="margin: auto;color:red; font-weight:900; font-size:1.3rem;">DMが解放されていないため、メッセージをお送りすることができません。DMを解放してください。</p>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-6 container">
        <router-link to="/recommends?profile_list=true" v-if="this.sex==2 && this.payment_conf==1">
          <div class="white-card">
            <p>レコメンド件数</p>
            <p class="rec-count">{{recommend_count}}</p>
          </div>
        </router-link>
        <div class="white-card" v-if="this.sex==2 && this.payment_conf!=1">
          <p>レコメンド件数</p>
          <p class="rec-count">{{recommend_count}}</p>
        </div>
        <v-dialog
          v-model="femalePremiumDialog"
          width="500"
        >
          <div style="background:white;color: #222222;padding:20px;">
          <p style="font-weight:900;">マッチン以外の男性プロフィールは</p>
          <p class="matching-success">女性向け<br>プレミアムプラン</p>
          <div class="" style="text-align:left; font-weight:700;">
          <p><span class="span-highlight">1.</span> 過去にノーマッチンにした男性をチェック！<br> → マッチン、ノーマッチンを<span class="span-highlight">やり直せる！</span></p>
          <p><span class="span-highlight">2.</span> レコメンド回数が１日10回！<br> → 更に性癖ちゃんからレコメンドが１日<span class="span-highlight">３回</span>に！</p>
          <p><span class="span-highlight">3.</span> タイムラインから自分のプロフィールを非表示に！<br> → レコメンドからだけ<span class="span-highlight">じっくり選べる！</span></p>
          <router-link to="/payment_select"><button class="btn-recommend-request">プランページへ</button></router-link>
          </div>
          </div>
        </v-dialog>
        
          <div class="white-card" v-show="this.sex==1 && payment_conf==1">
            <p>レコメンド件数</p>
            <p class="rec-count">{{recommend_count}}</p>
          </div>
        </div>
        <div class="col-6 container">
        <router-link to="/recommends?status=matching&profile_list=true" v-show="this.sex==2">
          <div class="white-card">
            <p>マッチング件数</p>
            <p class="matching-count">{{matching_count}}</p>
          </div>
        </router-link>
        <router-link to="/recommends/male?status=matching&profile_list=true" v-show="this.sex==1">
          <div class="white-card">
            <p>マッチング件数</p>
            <p class="matching-count">{{matching_count}}</p>
          </div>
        </router-link>
        </div>
        <div class="col-6 container">
          <router-link to="/recommends/male?status=liked&profile_list=true" v-show="this.sex==1">
          <div class="white-card">
            <p>もらったいいね数<span class="sub-p">(未チェック数)</span></p>
            <p class="liked-count">{{like_count}}<span class="liked-count-new">({{ like_count_new }})</span></p>
          </div>
          </router-link>
          <router-link to="/recommends?status=liked&profile_list=true" v-show="this.sex==2">
          <div class="white-card">
            <p>もらったいいね数<span class="sub-p">(未チェック数)</span></p>
            <p class="liked-count">{{like_count}}<span class="liked-count-new">({{ like_count_new }})</span></p>
          </div>
          </router-link>
        </div>
        <div class="col-6 container">
          <router-link to="/recommends/male?status=send_like&profile_list=true" v-show="this.sex==1">
          <div class="white-card">
            <p>送ったいいね</p>
            <img :src="require('@/assets/Bhair01.png')" style="width:140px; height:140px; border-radius:70px; border:3px solid white;">
          </div>
          </router-link>
          <router-link to="/recommends?status=send_like&profile_list=true" v-show="this.sex==2">
          <div class="white-card">
            <p>送ったいいね</p>
            <img :src="require('@/assets/Bhair01.png')" style="width:140px; height:140px; border-radius:70px; border:3px solid white;">
          </div>
          </router-link>
        </div>
      </div>
    </div>

    <h2>限定コラボ企画</h2>
    <v-slide-group multiple center-active show-arrows="always" >
      <v-slide-item>
        <v-card style="margin: 0 20px 0 0; width: 200px; height: 200px;" href="https://service.seiheki-matching.jp/seinsmous" target="_blank">
          <v-img class="white--text align-end" height="200px" src="@/assets/modal/seinsmous.jpg"/>
        </v-card>
      </v-slide-item>
      <v-slide-item>
        <v-card style="margin: 0 20px 0 0; width: 200px; height: 200px;" href="https://service.seiheki-matching.jp/sakuranokoineko-2" target="_blank">
          <v-img class="white--text align-end" height="200px" src="@/assets/modal/pop.jpg"/>
        </v-card>
      </v-slide-item>
      <v-slide-item>
        <v-card style="margin: 0 20px 0 0; width: 200px; height: 200px;" href="https://service.seiheki-matching.jp/dmm_seiheki" target="_blank">
          <v-img class="white--text align-end" height="200px" src="@/assets/modal/dmm_modal.png"/>
        </v-card>
      </v-slide-item>
      <v-slide-item>
        <v-card style="margin: 0 20px 0 0; width: 200px; height: 200px;" href="https://service.seiheki-matching.jp/beyourlover_seiheki_collab" target="_blank">
          <v-img class="white--text align-end" height="200px" src="@/assets/modal/suyatsu_modal.png"/>
        </v-card>
      </v-slide-item>
      <v-slide-item>
        <v-card style="margin: 0 20px 0 0; width: 200px; height: 200px;" href="https://service.seiheki-matching.jp/onayami-kaiketsu" target="_blank">
          <v-img class="white--text align-end" height="200px" src="@/assets/modal/note__modal.png"/>
        </v-card>
      </v-slide-item>
      <v-slide-item>
        <v-card style="margin: 0 20px 0 0; width: 200px; height: 200px;" href="https://service.seiheki-matching.jp/lovecosmetic-2022" target="_blank">
          <v-img style="max-width: 200px;" class="white--text align-end" height="200px" src="@/assets/modal/lovecosmetic2_banner.png"/>
        </v-card>
      </v-slide-item>
      <v-slide-item>
        <v-card style="margin: 0 20px 0 0; width: 200px; height: 200px;" href="https://service.seiheki-matching.jp/gme_seiheki" target="_blank">
          <v-img class="white--text align-end" height="200px" src="@/assets/banner/banner_gme.png"/>
        </v-card>
      </v-slide-item>
    </v-slide-group>
    <div v-show="false">
      <h2>運営事務局からのお知らせ</h2>
      
      <div class="container white-card left" style="display:flex; flex; flex-directon:row;">
        <div style="width: 100px; border-right:1px solid #3d3d3d; align-self:center;">
          <p style="margin:0">{{info_ts | moment}} </p>
        </div>
        <div style="flex-basis: 80%;">

          <p v-if="info_url==''||info_url==null" style="margin:0; padding-left:10px;">{{info}}</p>
          <a :href="info_url" target='_blank' v-else style="color: #3d3d3d;margin:0; padding-left:10px;">{{info}} <Icon name="external-link-alt" scale="0.8" style="color:gray"/></a>
        </div>
      </div>
    </div>
    
    <h2>確認事項</h2>
    <div class="container white-card left" style="display:flex; flex; flex-directon:row;">
      <div style="width: 100px; border-right:1px solid #3d3d3d; align-self:center;">
        <p style="margin:0">年齢確認</p>
      </div>
      <div style="flex-basis:80%;">
        <p v-if="age_conf==2" style="margin:0; padding-left:10px;"><Icon name="check-square" scale="1" style="color:#f85b8c"/>{{ageConf}}</p>
        <p v-else style="margin:0; padding-left:10px; color:#F52876;"><router-link to="/age_confirmation" style="color:#F52876;">{{ageConf}}</router-link></p>
      </div>
    </div>
    <div v-if="sex==1">
      <h2>決済情報</h2>
      <div class="container white-card left" style="display:flex; flex; flex-directon:row;">
        <div style="width: 100px; border-right:1px solid #3d3d3d; align-self:center;">
          <p style="margin:0">決済情報</p>
        </div>
        <div style="flex-basis:80%;">
          <p v-if="payment_conf==1" style="margin:0; padding-left:10px;"><Icon name="check-square" scale="1" style="color:#f85b8c"/>{{paymentConf}}</p>
          <p v-else style="margin:0; padding-left:10px; color:#F52876;"><router-link to="/payment_information" style="color:#F52876;">{{paymentConf}}</router-link></p>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .matching-success{
    font-weight: 900;
    font-size: 2.5rem;
    background: linear-gradient(315deg, rgba(250, 160, 152, 0.9), rgba(248, 96, 141, 0.9) 34%, rgba(113, 129, 237, 0.9) 69%, rgba(118, 82, 230, 0.9));
    -webkit-background-clip: text;
    color: transparent;
  }
  .span-highlight{
    font-size:1.2rem;
    font-weight:900;
    color:#F52876;
  }
</style>
<style>
  .modal-page{
    position:fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background: #0000003d;
    z-index:9999;
  }
  .modal-box{
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    border-radius: 5px;
    background: #fff;
    width: 90%;
    max-width: 350px !important;
  }
  .matching-count{
    font-size: 5rem;
    font-weight: 900;
    color: #F74481;
    font-family: Montserrat;
  }
  
  .liked-count{
    font-size: 5rem;
    font-weight: 900;
    color: #FF8C00;
  background: -webkit-linear-gradient(0deg,#8350DE, #DF468B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    font-family: Montserrat;   
  }
  .sub-p{
    font-size:0.7rem;
  }
  .liked-count-new{
    font-size:2rem;
  }
  .rec-count{
    font-size: 5rem;
    font-weight: 900;
    color: #6C35D9;
    font-family: Montserrat;
  }
.small-link{
  color: #4B4B4B;
  font-size:0.85rem;
}
.small-link:hover{
  color: #4B4B4B;
  font-size:0.85rem;
  text-decoration: none;
}

  h2{
    border-left: 5px solid ;

    border-image: linear-gradient(0deg, rgba(96,33,220,1) 0%, rgba(248,132,140,1) 100%);
    border-image-slice: 0 0 0 5;
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 900;
    color: #4B4B4B;
    text-align: left;
    margin-top: 50px;
    border-bottom: none;
    border-right: none;
    border-top: none;
  }
  
  .left{
    text-align: left;
  }
  
  .white-card{
    border-radius: 5px;
    background: white;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
  .btn-rank-request{
    background: linear-gradient(#70708c, #5b577a);
    border-radius: 320px;
    color: #ffffff;
    font-family: Lato;
    font-size: 1.2rem;
    font-weight: 900;
    height: auto;
    line-height: 1.4;
    padding: 10px 15px 10px 15px;
    text-align: center;
    width: auto;
    max-width: 100%;
    justify-content: center;
}
  .btn-recommend-request{
    /*background: linear-gradient(#70708c, #5b577a);*/
    background: #F74481;
    border-radius: 320px;
    color: #ffffff;
    font-family: Lato;
    font-size: 1.2rem;
    font-weight: 900;
    height: auto;
    line-height: 1.4;
    padding: 10px 15px 10px 15px;
    text-align: center;
    width: auto;
    max-width: 100%;
    width: 100%;
    justify-content: center;

}
  .btn-recommend-request-mannual{
    /*background: linear-gradient(#70708c, #5b577a);*/
    background: #6C41DC;
    border-radius: 320px;
    color: #ffffff;
    font-family: Lato;
    font-size: 1.2rem;
    font-weight: 900;
    height: auto;
    line-height: 1.4;
    padding: 10px 15px 10px 15px;
    text-align: center;
    width: auto;
    max-width: 100%;
    width: 100%;
    justify-content: center;
}
.limit-announce-text{
  color: #F52876;
  margin-bottom: 0;
  font-weight: 900;
  font-size: 1.1rem;
}
.btn-comp{
  color: white;
  border: none;
  border-radius: 30px;
  width: 80%;
  max-width: 300px;
  margin-top: 30px;
  padding: 12px;
  font-size: 1rem;
  font-weight: 900;
}

.login-btn{
  background: rgb(248,70,129);
  background: linear-gradient(90deg, rgba(248,70,129,1) 0%, rgba(255,150,145,1) 100%);
}

.signin-btn{
  background: rgb(97,34,219);
  background: linear-gradient(90deg, rgba(97,34,219,1) 0%, rgba(91,103,229,1) 100%);
  margin-top: 3px;
}


.v-slide-group__content {
  justify-content: center;
}
.v-slide-group:not(.v-slide-group--has-affixes) > .v-slide-group__prev,
.v-slide-group:not(.v-slide-group--has-affixes) > .v-slide-group__next {
display: inherit !important;
}
.v-slide-group__next--disabled,
.v-slide-group__prev--disabled {
pointer-events: auto !important;
}
</style>

<script>
  import moment from "moment";
  import 'vue-awesome/icons'
  import Icon from 'vue-awesome/components/Icon'
  
  import axios from "../../util/axios";
  import Ranktile from '@/components/Ranktile.vue'

  import Spinner from 'vue-simple-spinner'

  export default {
    name: "Home",

  head: {
    title() {
      return {
        inner: '性癖マッチン',
        separator: '|',
        complement: '',
      }
    },
    meta: [
      { name: '性癖マッチン', content: '性癖マッチン', id: 'desc' }
    ]
  },
    data() {
      return {
        loginBonusDialog: false,
        AnouncementDialog: false,
        femalePremiumDialog: false,
        isLoading: true,
        completedSteps: 0,
        isLovecosme: false,
        isGme: false,
        isSuyatsu:false,
        isLovecosme2: false,
        isViewLovecosme: false,
        isViewGme: false,
        isViewSuyatsu:false,
        isViewLovecosme2: false,
        id:"",
        uid: "",
        client: "",
        access_token: "",
        expiry: "",
        token_type: "",
        email: "",
        twitterid: "",
        info:"",
        info_ts: "",
        info_url: "",
        payment_conf: "",
        age_conf: "",
        age_conf_file: false,
        data_all: "",
        rank: "80%",
        sex: "",
        rc: "",
        status:"",
        profile_complete_rate: {point:0},
        active_status:"",
        rank_req_flag: "",
        create_review:false,
        form_post:false,
        message_flag:false,
        message_text:"",
        matching_count: 0,
        recommend_count: 0,
        like_count: 0,
        like_count_new: 0,
        mannual_recommend: false,
        recommend_create_count: 0,
        age_conf_flag: this.$route.query.age_conf_flag,
        tutorial_check: false,
        login_bonus: null,
        login_bonus_amount: 0,
        unread_messages_size: 0,
        max_recommend_count: 6,
        manual_recommend_ticket: 0,
        manual_recommend_count: 0,
        anouncement_size: 0,
        send_like_count:0,
        notification_reads: [],
      };
    },
    computed:{
      paymentConf: function (){
        if(this.payment_conf == 1){
          return "決済登録済"
        } else {
          return "決済が確認できていません。決済の確認・登録を行なってください。"
        }
      },
      ageConf: function (){
        if(this.age_conf == 2){
          return `年齢確認済`
        } else if( this.age_conf == 1){
          return "年齢確認書類の再提出が必要です。"
        } else if ( this.age_conf_file) {
          return "年齢確認書類確認中"
        } else {
          return "年齢確認書類の提出が必要です。"
        }
      },
      ageConfMessage: function(){
        if(this.age_conf_flag == 1){
          return `年齢確認書類が提出されました。審査完了まで最大１営業日ほどお待ちください。`
        } else if(this.create_review == true) {
          return "レビューを投稿しました。"
        } else if(this.form_post==true){
          return "登録情報を更新しました。"
        } else if(this.message_text != ""){
          return this.message_text
        } else {
          return ""
        }
      }
    },

    methods: {
      async postNotificationRead(){
        const result = await axios({
          method : 'POST',
          url: '/api/notification_read',
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
          data: {
            notification_id: this.notification_reads[0].id
          }
        }).catch((e) => {
          e;
        });
        if(result.data.status == 200) {
          // this.getNotifications()
          if(this.notification_reads.length > 1){
            this.notification_reads.shift();
          }else {
            this.AnouncementDialog = false;
          }
        }
      },
      async getNewRecommend(){
        this.isLoading = true
          const result = await axios.get("/api/recommend_auto_recommend", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          e;
        });
        this.isLoading=false
        if(result.data.success == true ){
          this.$router.push("/profile?id="+result.data.recommend)
        } else {
          this.message_text = "男性が見つかりませんでした。プロフィールや条件を変更してみてください。"
          this.hasMessage()
        }
      },
      hasMessage(){
        if (
          (this.age_conf_flag == 1 || this.create_review == true || this.form_post == true)||
          (this.ng_commit) || this.message_text != ''
          ){
          setTimeout(() => {
            this.message_flag = true
          },1000)
          setTimeout(() => {
            this.message_flag = false}
            ,5000
          )
        }
      },
      async onModal(t){

        const result_modal = await axios.get("/api/modal/list", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          e;
        });

        let is_gme = true
        let is_suyatsu = true
        let is_love2 =true
        is_love2
      
        result_modal.data.modal_list.forEach(function(list){
          if(list.code == 2){
            is_gme = false
          }
          else if(list.code == 3){
            is_suyatsu = false
          }
          else if(list.code ==4){
            is_love2 = false
          }
        })

        if(t == 1){
          // lovecosme
          this.isLovecosme2 = false
          this.isGme = is_gme

          if(this.isViewLovecosme2){
            let result = await axios.post("/api/modal/is_view", {
              headers: {
                uid: this.uid,
                "access-token": this.access_token,
                client: this.client,
              },
              user_id:this.id,
              code: "4",
            }).catch((e) => {
              console.error(e)
            })
            console.error(result)
          }
        }
        else if(t == 2){
          this.isGme = false
          this.isSuyatsu = is_suyatsu

          if(this.isViewGme){
            let result = await axios.post("/api/modal/is_view", {
              headers: {
                uid: this.uid,
                "access-token": this.access_token,
                client: this.client,
              },
              user_id:this.id,
              code: "2",
            }).catch((e) => {
              console.error(e)
            })
            console.error(result)
          }
        }
        else if(t == 3){
          this.isSuyatsu = false

          if(this.isViewSuyatsu){
            let result = await axios.post("/api/modal/is_view", {
              headers: {
                uid: this.uid,
                "access-token": this.access_token,
                client: this.client,
              },
              user_id:this.id,
              code: "3",
            }).catch((e) => {
              console.error(e)
            })
            console.error(result)
          }
        }
      },
      async getNotifications() {
        const result = await axios.get("/api/notification_reads", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          e;
        });
        this.notification_reads = result.data.user_notifications;
        if(result.data.user_notifications_count > 0){
          this.AnouncementDialog = true;
        }
      },
      async getAccountData() {
          const result = await axios.get("/api/account", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
            "token-type": this.token_type,
            expiry: this.expiry
          },
        }).catch((e) => {
          e;
        });
        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          this.redirectLogin();
          return;
        }
        if (!result.data.email) {
          // エラーの場合ログイン画面へ遷移させる
          this.redirectLogin();
          return;
        }

        const result_modal = await axios.get("/api/modal/list", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          e;
        });

        const cnt = result_modal.data.modal_list.length

        if(cnt == 0){
          this.isLovecosme2 = true
        }
        else{
          let is_love = true
          let is_love2 = true
          is_love
          is_love2
          let is_gme = true
          let is_suyatsu = true
          result_modal.data.modal_list.forEach(function(list){
            if(list.code == 1){
              is_love = false
            }
            if(list.code == 2){
              is_gme = false
            }
            if(list.code == 3){
              is_suyatsu = false
            }
            if(list.code == 4){
              is_love2 = false
            }
          })
          if(is_love2){
            this.isLovecosme2 = true
          }
          else if(is_gme){
            this.isGme = true
          }
          else if(is_suyatsu){
            this.isSuyatsu = true
          }
        }
        this.id = result.data.id
        this.email = result.data.email;
        this.twitterid = result.data.twitterid;
        this.twitter_name=result.data.twitter_name
        this.info = result.data.notification[0].info;
        this.info_ts = result.data.notification[0].created_at;
        this.info_url = result.data.notification[0].url;
        this.payment_conf= result.data.payment_conf;
        this.age_conf = result.data.age_conf; 
        this.age_conf_file = result.data.age_conf_file; 
        this.data_all = result.data;
        this.status = result.data.status;
        this.active_status = result.data.active_status;
        this.sex = result.data.sex;
        this.profile_complete_rate = result.data.profile_complete_rate;
        localStorage.setItem('sex', this.sex);
        localStorage.setItem('payment_conf', this.payment_conf);
        this.rank = result.data.rank;
        this.rank_req_flag = result.data.rank_req_flag;
        this.matching_count = result.data.matching_count;
        this.like_count = result.data.like_count;
        this.like_count_new = result.data.like_count_new;
        this.recommend_count = result.data.recommend_count;
        this.recommend_id = result.data.recommend_id;
        this.matching_id = result.data.matching_id;
        this.suspend_id = result.data.suspend_id;
        this.action = result.data.action;
        this.recommendable = result.data.recommendable;
        this.rc = result.data.rc;
        this.recommend_create_count = result.data.recommend_create_count;
        this.mannual_recommend = result.data.mannual_recommend;
        this.tutorial_check = result.data.tutorial_check;
        this.login_bonus = result.data.login_bonus;
        this.login_bonus_amount = result.data.login_bonus_amount;
        this.unread_messages_size = result.data.unread_messages_size;
        this.manual_recommend_ticket = result.data.manual_recommend_ticket;
        this.manual_recommend_count = result.data.manual_recommend_count;
        this.anouncement_size = result.data.anouncement_size;
        this.send_like_count = result.data.send_like_count;
        // localStorage.setItem('unread_messages_size', this.unread_messages_size); 
        this.$store.commit('change_unread_messages_size', this.unread_messages_size);

        // if (result.data.payment_conf==1){
        //   this.max_recommend_count = 10;
        // }
        if (this.matching_count==null){
          this.matching_count = 0;
        }
        if (this.recommend_count == null){
          this.recommend_count = 0;
        }
        if(this.age_conf==3 || this.age_conf==4){
          this.logout();
        }
        if(this.active_status=='inactive'){
          this.logout(); 
        }
        this.isLoading = false;
        this.redirectForm();
        
        if(this.sex==2 && this.tutorial_check==0 && this.age_conf == 2 && this.twitter_name != null){
          this.$router.push("/tutorial");
        }
        if(this.login_bonus==0 || this.login_bonus == null){
          this.createLoginBonus();
        }
        setTimeout(this.setCompletedSteps, 1000);
      },

      async createLoginBonus(){
        const result = await axios({
          method : 'POST',
          url    : "/api/login_bonus/create",
          headers: {'Content-Type': 'application/json',
                  uid: this.uid,
                  "access-token": this.access_token,
                  client: this.client,
                },
        }).catch((e) => {
          e
          // this.$router.push('/login');
        });
        if(result.data.success == true){
          this.login_bonus_amount = result.data.amount;
          this.loginBonusDialog = true;
        }
      },
      async submitStatusActivate(){
        this.isLoading = true;
        const result = await axios({
          method : 'POST',
          url    : "/api/female_status_activate_transaction",
          headers: {'Content-Type': 'application/json',
                  uid: this.uid,
                  "access-token": this.access_token,
                  client: this.client,
                },
          data: {
            female_status_activate_transaction:{
              status_old: this.status,
            }}
        }).catch((e) => {
          e
          this.$router.push('/login');
        });

        if (!result) {
          this.$router.push('/login');
          this.isLoading = false;
          return;
        }
        this.status = 4;
        this.isLoading = false;
      },
      setCompletedSteps(){
        // 整数に変換
        this.completedSteps = (this.profile_complete_rate.point/ 100)*5;
      },
      redirectLogin() {
        //ページ遷移
        this.$router.push("/login");
      },
      async submitMannualRecommend(){
        if (this.manual_recommend_ticket > 0){
        this.isLoading = true;
        const result = await axios({
          method : 'POST',
          url    : "/api/mannual_recommend",
          headers: {'Content-Type': 'application/json',
                  uid: this.uid,
                  "access-token": this.access_token,
                  client: this.client,
                },
        }).catch((e) => {
          e
          this.$router.push('/login');
        });

        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          this.$router.push('/login');
          this.isLoading = false;
          return;
        }
        this.mannual_recommend=true;
        this.manual_recommend_ticket = this.manual_recommend_ticket - 1;
        this.manual_recommend_count = this.manual_recommend_count + 1;
        this.isLoading = false;
        if(status=='ng'){
            this.$router.push('/home');
        }
        }
      },

      redirectForm() {
        if(this.sex == 1) {
          // if(this.twitterid == null || this.twitterid == ''){
          //   this.$router.push("/maletutorial"); 
          // }
          //年齢確認ページに移動
          //決済ページへ移動
          if(this.payment_conf != 1 ){
            this.$router.push("/payment_information")
          }
          else if(this.age_conf != 2 && !this.age_conf_file){
            this.$router.push("/age_confirmation")
          }
          else if(this.twitter_name == null && this.payment_conf == 1 && this.sex == 1){
            this.$router.push("/form"); 
          }
        } else if (this.sex == 2) {
          if(this.twitter_name == null){
            this.$router.push("/femaleform");
          } else if(this.age_conf != 2 && !this.age_conf_file){
            this.$router.push("/age_confirmation")
          }
        }
      },
      async logout() {
          localStorage.removeItem('uid')
          localStorage.removeItem('client')
          localStorage.removeItem('access-token')
        // const self = this;
        const result = await axios.delete("/api/auth/sign_out",{
          // test: { test: "test" },
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          console.error(e);
        });

        if (!result) {
          this.message = "エラー";
          this.$router.push("/login");
          return;
        }
        if (!result) {
          this.message = "エラー";
          this.$router.push("/login");
          return;
        }

        if (result) {
          //結果を基にページ遷移
          localStorage.setItem('sex', 4)
          localStorage.removeItem('uid')
          localStorage.removeItem('client')
          localStorage.removeItem('access-token')
          localStorage.removeItem('sex')
          localStorage.setItem('message', '18歳未満の方はご利用いただけません。')
          this.$router.push("/login");
        }
      },
    },
    filters: {
        moment:function(date){
            return moment(date).format('YYYY/MM/DD');
        }
    },
    mounted() {
      localStorage.removeItem('message')
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.expiry = localStorage.getItem('expiry')
      this.token_type = localStorage.getItem('token_type')
      this.create_review = this.$route.query.create_review 
      this.form_post = this.$route.query.form_post
      this.ng_commit = this.$route.query.ng_commit
      this.getAccountData();
      this.getNotifications();
      
      this.hasMessage();
    },
    components: {
      Ranktile,
      Icon,
      Spinner,
    }
  };
</script>